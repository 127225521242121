import React from 'react';
import PropTypes from 'prop-types';
import {Listing, MilestoneGroup, MilestoneGroupState, MilestoneType, MilestoneTypeCollection} from "../vmc-types";
import {CloseButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {vmcService} from "../Xhr/vmc.service";
import moment from "moment";
import Loading from "./Loading";

interface IProps {
    listing: Listing,
    group: MilestoneGroup | null,
    state: MilestoneGroupState | null,
    onClose: () => void | null
}

interface IState {
    loading: boolean,
    milestoneTypes: MilestoneTypeCollection | null,
    error: boolean
}

class MilestoneGroupDetail extends React.Component<IProps, IState> {
    static propTypes: {};
    static defaultProps: {};
    private _isMounted: boolean;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            milestoneTypes: null,
            error: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    private loadData() {
        if (this._isMounted && this.props.group) {
            this.setState({loading: true});
            vmcService.milestoneGroupWorkflow(this.props.listing.id, this.props.group.id).then(response => {
                if (this._isMounted) {
                    this.setState({loading: false, error: false, milestoneTypes: response.data});
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (+prevProps.listing.id !== +this.props.listing.id || (prevProps.group && +prevProps.group.id) !== (this.props.group && +this.props.group.id)) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    private findMilestone(milestoneId: undefined | number) {
        if (milestoneId && this.state.milestoneTypes && this.state.milestoneTypes.included) {
            return this.state.milestoneTypes.included.find(milestone => {
                return milestone.type === 'milestone' && milestone.id === milestoneId;
            });
        } else {
            return null;
        }
    }

    private findMilestoneId(type: MilestoneType) {
        return type.relationships &&
            type.relationships.milestones &&
            type.relationships.milestones.data &&
            type.relationships.milestones.data[0] &&
            type.relationships.milestones.data[0].id;
    }

    render() {
        if (!this.props.group) {
            return <></>;
        }
        let rows: any[] = [];
        if (this.state.milestoneTypes && this.state.milestoneTypes.data) {
            rows = this.state.milestoneTypes.data.map(type => {
                let milestoneId = this.findMilestoneId(type);
                let milestone = this.findMilestone(milestoneId);
                return <div className={'col-6 col-md-4 mt-3'} key={type.id}>
                    <div className={'py-3 border-bottom'}>
                        <h5 className={'text-left text-muted'}>{type.attributes.name}</h5>
                        {milestone &&
                        <h4 className={'text-left mb-0'}>{moment.unix(milestone.attributes.completed_at).format('D MMM Y')}</h4>
                        }
                        {!milestone &&
                        <h4 className={'text-left text-muted mb-0'}>Not Set</h4>
                        }
                    </div>
                </div>
            });
        }
        const state = this.props.state;
        return (
            <div className={'row bg-white shadow mb-3'}>
                <div className="col px-0">
                    <div className={'pt-4 pb-3 px-3'}>
                        <CloseButton onClick={() => this.props.onClose ? this.props.onClose() : null}/>
                        <h3 className={'text-left'}>{this.props.group.attributes.name}</h3>
                        {state && state.context &&
                        <p className={'text-left mb-0'}>
                            {!state.requires_action && state.requires_confirmation &&
                            <><FontAwesomeIcon icon={faQuestionCircle}/>&nbsp;</>
                            }
                            {state.requires_action &&
                            <><FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;</>
                            }
                            {!state.requires_action && !state.requires_confirmation &&
                            <><FontAwesomeIcon icon={faInfoCircle}/>&nbsp;</>
                            }
                            {state.context}
                        </p>
                        }
                        {this.state.loading &&
                        <Loading/>
                        }
                        {rows.length &&
                        <div className="row flex-wrap mt-n3">
                            {rows}
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

MilestoneGroupDetail.propTypes = {
    group: PropTypes.object,
    listing: PropTypes.object.isRequired,
    state: PropTypes.object,
    show: PropTypes.bool,
    onClose: PropTypes.func
};

MilestoneGroupDetail.defaultProps = {
    show: false
};

export default MilestoneGroupDetail;
