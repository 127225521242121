import React from 'react';
import './ReziActionApp.css';
import ReziApi from '../Xhr/ReziApi'
import DisabledMessage from './DisabledMessage';
import ErrorMessage from './ErrorMessage';
import {Context} from '../DataContracts/Context';
import Chain from "../vmc/Chain";
import Loading from "../vmc/Loading";


interface IProps {
    Token: string;
    RefreshToken: string;
    WidgetId: number | null;
    ContainerId: string | null;
    ApiUrl: string;
    AppTitle: string;
    Tel: string;
    Email: string;
    Loading: boolean;
    Context: Context
}

interface IState {
    Enabled: boolean;
    AsyncError: boolean;
    ErrorDetail: any;
    ReziApi: ReziApi | null;
}

class App extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            Enabled: true,
            AsyncError: false,
            ErrorDetail: {},
            ReziApi: null
        }
    }

    componentDidMount() {
        this.createReziWrapper();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.ApiUrl !== prevProps.ApiUrl || this.props.Token !== prevProps.Token || this.props.RefreshToken !== prevProps.RefreshToken) {
            this.createReziWrapper();
        }
    }

    private createReziWrapper() {
        if (this.props.ApiUrl && this.props.Token && this.props.RefreshToken) {
            this.setState({ReziApi: new ReziApi(this.props.ApiUrl, this.props.Token, this.props.RefreshToken)});
        }
    }

    render() {
        if (this.state.Enabled && !this.state.AsyncError && this.state.ReziApi) {
            return <div className={'container bg-light position-absolute'} style={{top: 0, right: 0, bottom: 0, left: 0}}>
                <div className={'row bg-white justify-content-center pb-3 border-bottom mb-3'}>
                    <div className="col-auto px-0">
                        <img alt="View My Chain Logo" src="//images.ctfassets.net/019o4x34ndl7/6MwRYoHKRUCkMey8SQseOy/d741f4e325238e280142d27d39695c5e/logo.png"/>
                    </div>
                </div>
                <Chain rezi={this.state.ReziApi} context={this.props.Context}/>
            </div>
        }
        if (!this.state.Enabled && !this.state.AsyncError) {
            return <DisabledMessage AppTitle={this.props.AppTitle}/>
        }
        if (this.state.AsyncError) {
            return <ErrorMessage AppTitle={this.props.AppTitle} Tel={this.props.Tel} Email={this.props.Email} ErrorMessage={this.state.ErrorDetail} ErrorStack={'Async Error'}/>
        }
        return <>
            <p className={'text-center'}>Booting...</p>
            <Loading/>
        </>;
    }
}

export default App;
