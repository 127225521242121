import React from 'react';

import './ReziActionAppWrapper.css';
import {AppMessaging} from './AppMessaging';
import Spinner from './Components/Spinner';
import ErrorBoundary from './Components/ErrorBoundary';
import ReziActionApp from './Components/ReziActionApp';


interface IProps {
}

interface IState {
    WidgetId: number | null;
    ContainerId: string | null;
    Token: string;
    RefreshToken: string;
    ApiUrl: string;
    Context: any;
    Loading: boolean
}

class ReziActionAppWrapper extends React.Component<IProps, IState> {
    AppName: string = "VMC Chain App";
    AppTitle: string = "VMC Chain App";
    SupportEmail: string = "support@viewmychain.com";
    SupportTel: string = "07007 007007";
    ContractedHeight: number = 2;
    ExpandedHeight: number = 2;
    Level: string = "";


    constructor(props: IProps) {
        super(props);

        this.state = {
            WidgetId: 0,
            ContainerId: "",
            Token: "",
            RefreshToken: "",
            ApiUrl: "",
            Context: {},
            Loading: false
        }
    }


    componentDidMount() {
        console.log("The Rezi App ReziActionAppWrapper did mount!");
        const urlParams = new URLSearchParams(window.location.search);
        let widgetId: number = 0;
        const param_widgetId: string | null = urlParams.get("widgetId");
        if (param_widgetId) widgetId = parseInt(param_widgetId);
        const param_containerId: string | null = urlParams.get("containerId");

        this.setState({WidgetId: widgetId, ContainerId: param_containerId});

        AppMessaging(widgetId, param_containerId, this.ContractedHeight, this.ExpandedHeight, this.startApp, this.expanded, this.contracted, this.externalCommand);
    }

    startApp = (token: string, refresh: string, apiUrl: string, context: any) => {
        console.log("App Was started By IFrame Container");
        this.setState({Token: token, RefreshToken: refresh, ApiUrl: apiUrl, Context: context, Loading: false});
    }

    expanded = (height: number) => {

    }

    contracted = (height: number) => {

    }

    externalCommand = (command: any) => {

    }


    render() {
        console.log(this.state);
        return (
            <div className="App">
                <Spinner name={this.AppTitle} loading={this.state.Loading}/>
                {!this.state.Loading &&
                <ErrorBoundary AppTitle={this.AppTitle} Tel={this.SupportTel} Email={this.SupportEmail}>

                    <ReziActionApp Token={this.state.Token} WidgetId={this.state.WidgetId} ContainerId={this.state.ContainerId} Loading={this.state.Loading}
                                   ApiUrl={this.state.ApiUrl} RefreshToken={this.state.RefreshToken} AppTitle={this.AppTitle} Tel={this.SupportTel}
                                   Email={this.SupportEmail} Context={this.state.Context}/>

                </ErrorBoundary>
                }
            </div>
        );
    }
}

export default ReziActionAppWrapper;
