import React from 'react';
import PropTypes from 'prop-types';
import {Listing, MilestoneGroup, MilestoneGroupStates} from "../vmc-types";
import {vmcService} from "../Xhr/vmc.service";
import Loading from "./Loading";
import MilestoneGroupBlock from "./MilestoneGroupBlock";
import styles from "./ChainProgressBar.module.scss";
import MilestoneGroupDetail from "./MilestoneGroupDetail";

interface IProps {
    listing: Listing;
}

interface IState {
    loading: boolean,
    groups: MilestoneGroup[],
    states: MilestoneGroupStates | null,
    error: boolean,
    selectedGroup: MilestoneGroup | null
}

class ChainProgressBar extends React.Component<IProps, IState> {
    static propTypes: {};
    static defaultProps: {};
    private _isMounted: boolean;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            groups: [],
            error: false,
            states: null,
            selectedGroup: null,
        };
        this._isMounted = false;
        this.closeMilestoneGroupDetail = this.closeMilestoneGroupDetail.bind(this);
        this.handleMilestoneGroupChange = this.handleMilestoneGroupChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({loading: true});
        vmcService.milestoneGroups().then(response => {
            if (this._isMounted) {
                this.setState({loading: false, error: false, groups: response.data.data});
            }
        });
        vmcService.groupStates(this.props.listing.id).then(response => {
            if (this._isMounted) {
                this.setState({states: response.data});
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    closeMilestoneGroupDetail() {
        if (this._isMounted) {
            this.setState({selectedGroup: null});
        }
    }

    handleMilestoneGroupChange(group: MilestoneGroup) {
        if (this._isMounted) {
            if (this.state.selectedGroup && +group.id === +this.state.selectedGroup.id) {
                this.closeMilestoneGroupDetail();
            } else {
                this.setState({selectedGroup: group});
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }
        if (this.state.error) {
            return <p>We've run into a problem loading this listings chain data.</p>
        }
        let listing = this.props.listing.attributes;
        const groups = this.state.groups.map(group => {
            let groupState = this.state.states && this.state.states.data[group.attributes.name];
            return <div className="col px-0" key={group.id}>
                <MilestoneGroupBlock listing={this.props.listing} group={group}
                                     state={groupState ? groupState : null}
                                     onClick={this.handleMilestoneGroupChange}/>
            </div>;
        });
        let groupState = this.state.selectedGroup && this.state.states && this.state.states.data[this.state.selectedGroup.attributes.name];
        return (
            <>
                <div className={'row flex-wrap mt-2 ' + styles.bar}>
                    <div className="col-12 col-md-4 px-0">
                        <div className="d-flex flex-column h-100">
                            <h5 className={'bg-primary mb-0 p-2 text-left'}>{listing.address_street_name}</h5>
                            <p className={'bg-info mb-0 p-2 text-light flex-fill text-left'}>
                                {listing.branch_name}
                            </p>
                        </div>
                    </div>
                    {groups}
                </div>
                <MilestoneGroupDetail group={this.state.selectedGroup}
                                      listing={this.props.listing}
                                      onClose={this.closeMilestoneGroupDetail}
                                      state={groupState}/>
            </>
        );
    }
}

ChainProgressBar.propTypes = {
    listing: PropTypes.object.isRequired
};

ChainProgressBar.defaultProps = {};

export default ChainProgressBar;
