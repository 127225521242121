import React from 'react';
import {Address} from "../rezi-types";
import {vmcService} from "../Xhr/vmc.service";
import {Listing} from "../vmc-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Loading from "./Loading";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./Search.module.scss";
import ListingSearchResult from "./ListingSearchResult";

interface IProps {
    address: Address,
    onSelect: (listingId: number) => void
}

interface IState {
    term: string,
    results: Listing[],
    searching: boolean,
    error: boolean
}

function fullAddress(address: Address) {
    let addressString = '';
    if (address) {
        addressString = [
            address.OrganizationName,
            address.Number,
            address.BuildingName,
            address.Street,
            address.Town,
            address.Locality,
            address.County,
            address.Postcode,
        ].filter(v => v !== '').join(', ');
    }
    return addressString;
}

const searchAPIDebounced = AwesomeDebouncePromise(vmcService.searchListings, 500);

class Search extends React.Component<IProps, IState> {
    private _isMounted: boolean;

    constructor(props: IProps) {
        super(props);
        let address = this.props.address;
        let addressString = fullAddress(address);

        this.state = {
            term: addressString,
            results: [],
            searching: true,
            error: false
        };
        this._isMounted = false;
        this.handleTermChange = this.handleTermChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.search();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        let newTerm = fullAddress(this.props.address);
        if (this.state.term === '' && newTerm !== fullAddress(prevProps.address) && this._isMounted) {
            this.setState({term: newTerm});
        }
        if (this.state.term.length > 2 && this.state.term !== prevState.term) {
            this.search();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleTermChange(e: any) {
        let term = e.target.value;
        if (this._isMounted) {
            this.setState({term: term});
        }

        if (term.length > 2) {
            this.search(term);
        }
        if (term.length === 0) {
            if (this._isMounted) {
                this.setState({results: []});
            }
        }
    }

    search(term = this.state.term) {
        if (this._isMounted) {
            if (term === '') {
                this.setState({results: []});
                return;
            }
            if (term !== this.state.term) {
                this.setState({results: []});
            }
            this.searchListings(term);
        }
    }

    async searchListings(term: string) {
        try {
            const result = await searchAPIDebounced(term);
            if (result.data) {
                if (result.data.data) {
                    if (this._isMounted) {
                        this.setState({
                            results: result.data.data,
                            searching: false,
                            error: false
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        results: [],
                        searching: false,
                        error: false
                    });
                }
            }
        } catch (e) {
            if (this._isMounted) {
                this.setState({searching: false, error: true})
            }
        }
    }

    render() {
        const listingRows = this.state.results.map(listing => <ListingSearchResult listing={listing} key={listing.id} onClick={() => this.props.onSelect(listing.id)}/>)
        return <>
            <div className="row justify-content-center">
                <div className="col-md-8 px-0">
                    <p className={'mb-0 alert alert-info'}>We haven't matched your property yet, select one of the properties below to see the chain data.</p>
                </div>
            </div>
            <div className="row justify-content-center sticky-top">
                <div className="col-md-8 px-0">
                    <form id={'searchForm'} onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}>
                        <div className="form-group mb-2">
                            <div className="input-group rounded-pill">
                                <div className="input-group-prepend">
                                    <span className={"input-group-text " + styles.searchIcon} id={'searchAddOn'}>
                                        <FontAwesomeIcon icon={faSearch} size={'lg'}/>
                                    </span>
                                </div>
                                <input type="search" className={"form-control " + styles.searchBox} placeholder="Search" aria-label="Search"
                                       aria-describedby="searchAddOn"
                                       onChange={this.handleTermChange} value={this.state.term}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {this.state.searching &&
            <div className={'mt-2'}>
                <Loading/>
            </div>
            }

            {this.state.term !== '' && this.state.term.length > 2 &&
            !this.state.searching && this.state.results.length === 0 &&
            <p className={'text-center text-muted'}>Sorry we couldn't find any results try tweaking your search term.</p>
            }
            {this.state.results.length > 0 &&
            <div className={'row justify-content-center'}>
                <div className={'col col-md-8 px-0'}>
                    <div className={''}>
                        <ul className={'list-group'}>
                            {listingRows}
                        </ul>
                    </div>
                </div>
            </div>
            }
        </>
    }
}

export default Search;
