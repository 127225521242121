import React from 'react';
import PropTypes from 'prop-types';
import {Listing} from "../vmc-types";
import styles from "./LifterSearchResult.module.scss";

interface IProps {
    listing: Listing,
    onClick: (id: number) => void
}

class ListingSearchResult extends React.Component<IProps> {
    static propTypes: {};
    static defaultProps: {};

    constructor(props: IProps) {
        super(props);

        this.state = {};

    }

    render() {
        return (
            <div className={'d-flex mb-2 shadow rounded border ' + styles.result} onClick={() => this.props.onClick(this.props.listing.id)}>
                <div className={'text-light w-25 text-left p-2 address-box'}>
                    <h5 className={'text-white mb-0'}>{this.props.listing.attributes.address_street_name}</h5>
                    <p className={'small mb-0'}>{this.props.listing.attributes.address_area}</p>
                    <p className={'small mb-0'}>{this.props.listing.attributes.address_postal_code}</p>
                </div>
                <div className={'flex-fill p-2 bg-white'}>
                    <div className="d-flex align-items-center justify-content-between h-100">
                        <p className={'mb-0'}>{this.props.listing.attributes.branch_name}</p>
                        <p className={'mb-0 text-right'}>£{this.props.listing.attributes.price.toLocaleString()}</p>
                    </div>
                </div>
            </div>
        );
    }
}

ListingSearchResult.propTypes = {
    listing: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};

ListingSearchResult.defaultProps = {};

export default ListingSearchResult;
